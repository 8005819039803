import * as React from "react"
import Layout from "../components/Layout"
import Contact from "../components/Contact/Contact"
import Seo from "../components/SEO"
import {Alert, Calendar, ConfigProvider, DatePicker, Drawer, Space, theme, Timeline} from 'antd';
import {useState} from "react";
import Button from "../components/Button/Button";
const TestSite = () => {

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Seo title="Contact" />
            <Layout>
                <section style={{ paddingTop: '0vh' }}>
                    <div className="section">
                        <div className="container container__tight">
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: "#9db35d",
                                        colorBgBase: "#1b1b1b",
                                        colorBgContainer: "#1b1b1b",
                                        fontFamily: "Heebo, sans-serif",
                                    },
                                    algorithm: theme.darkAlgorithm
                                }}
                            >
                                <Button arrow={false} as={'span'} text={'Open'} onClick={showDrawer}/>

                                <Drawer size={"large"} title="Basic Drawer" placement="right" onClose={onClose} open={open} >
                                    <Calendar fullscreen={false} />
                                </Drawer>


                            </ConfigProvider>
                        </div>
                    </div>

                </section>
            </Layout>
        </>
    )
}

export default TestSite
